import React, { useContext } from 'react';

import { CheckoutContext } from './context';
import { checkoutFields, checkoutTerms, emailField } from './data';

import Portal from '../Portal';

import './form.css';

import { TitleColour } from '../../utils/variables';
import Loader from '../../images/icon-loader-dark.svg';

import loadComponents from '../Loadable';

const H2 = loadComponents('h2');
const Fields = loadComponents('form-fields');
const Payment = loadComponents('checkout-payments');

export default function CheckoutForm({
  payment,
  togglePayment,
  currency,
  total,
  orderId,
  items,
}) {
  const { checkout, createOrder } = useContext(CheckoutContext);
  const { processing, handlePaypalSubmit } = checkout;

  const paymentProps = {
    total,
    currency,
    active: payment,
    togglePayment,
    processing,
    handlePaypalSubmit,
    orderId,
    items,
  };

  return (
    <form
      name="checkout"
      className="checkoutForm"
      onSubmit={payment === 0 && createOrder}
    >
      {processing && (
        <Portal portal="modal">
          <div
            style={{
              backgroundColor: `rgba(255,255,255,0.8)`,
              display: `flex`,
              alignItems: `center`,
              justifyContent: `center`,
              position: `fixed`,
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 9999,
              flexDirection: `column`,
            }}
          >
            <img
              src={Loader}
              height="100px"
              width="100px"
              style={{ marginBottom: `1.875rem` }}
              alt="Processing order..."
            />
            <p style={{ textAlign: `center` }}>
              <strong style={{ fontSize: `1.75rem`, color: TitleColour }}>
                Processing order...
              </strong>
              <br />
              <strong>Warning:</strong> Do not close or refresh the page until
              your order has been confirmed.
            </p>
          </div>
        </Portal>
      )}
      <H2>Payment Details</H2>
      <div
        style={{
          display: `flex`,
          flexWrap: `wrap`,
          margin: `0 -0.625rem`,
        }}
      >
        <Fields data={emailField} />
        <Fields data={checkoutFields} />
        <Fields data={checkoutTerms} />
      </div>
      <Payment {...paymentProps} />
    </form>
  );
}
