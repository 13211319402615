export const emailField = [
  {
    type: 'input',
    inputType: 'email',
    width: '100%',
    name: 'Email Address',
    required: true,
  },
];

export const accountFields = [
  {
    type: 'input',
    inputType: 'checkbox',
    width: '100%',
    name: 'Create Account',
    label:
      'Would you like to create an account?<br /><small>Account holders can access their visa documentation at any time from the account dashboard and track the status of their application.</small>',
  },
  {
    type: 'input',
    inputType: 'password',
    width: '100%',
    name: 'Password',
    required: true,
    condition: [
      {
        field: 'create_account',
        operator: 'eq',
        value: true,
      },
    ],
  },
];

export const checkoutFields = [
  {
    type: 'input',
    inputType: 'text',
    width: '50%',
    name: 'First Name',
    required: true,
  },
  {
    type: 'input',
    inputType: 'text',
    width: '50%',
    name: 'Last Name',
    required: true,
  },
  {
    type: 'input',
    inputType: 'text',
    width: '100%',
    name: 'Street Address',
    required: true,
  },
  {
    type: 'input',
    inputType: 'text',
    width: '33.333%',
    name: 'Town / City',
    id: 'city',
    required: true,
  },
  {
    type: 'input',
    inputType: 'text',
    width: '33.333%',
    name: 'County / Province / Region',
    id: 'county',
    required: true,
  },
  {
    type: 'input',
    inputType: 'text',
    width: '33.333%',
    name: 'Postcode / Zipcode',
    id: 'post_code',
    required: true,
  },
  {
    type: 'input',
    inputType: 'phone',
    width: '50%',
    name: 'Phone Number',
    required: true,
  },
  {
    type: 'country',
    width: '50%',
    name: 'Country',
    required: true,
  },
];

export const saveField = [
  {
    type: 'input',
    inputType: 'checkbox',
    width: '100%',
    name: 'Save Address',
    label: 'Would you like to save your address to checkout faster next time?',
    // required: true,
  },
];

export const checkoutTerms = [
  {
    type: 'input',
    inputType: 'checkbox',
    width: '100%',
    name: 'Terms Conditions',
    label:
      'By placing this order I agree to the website <a href="/terms-conditions/">Terms & Conditions</a> and consent to my personal information being collected in order to complete this transaction. For more details on how your personal information is stored please review our <a href="/privacy-policy/">Privacy Policy</a>.',
    required: true,
  },
  {
    type: 'input',
    inputType: 'checkbox',
    width: '100%',
    name: 'Agreement',
    label:
      'I understand that Visa applications are not guaranteed and payment does not guarantee approval. Payment covers the cost of this application only and does cover any subsequent costs incurred in transit or by the embassy based on my personal circumstance or visa application type.',
    required: true,
  },
];
